@font-face {
  font-family: 'flowers';
  src: url('LookingFlowers-Script.ttf');
}

body {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}
